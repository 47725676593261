// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// swagger-ui
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'

// semantic-ui
import { Container, Header, Icon, Segment, Menu } from 'semantic-ui-react'

// services
import { isAuthenticated, isRegistered } from 'services/self'
import { updateUsagePlansAndApisList, getApi } from 'services/api-catalog'

// components
import ApisMenu from 'components/ApisMenu'
import SwaggerLayoutPlugin from 'components/SwaggerUiLayout'
import PageWithSidebar from 'components/PageWithSidebar'

// state
import { store } from 'services/state.js'
import { observer } from 'mobx-react'
export default observer(class ApisPage extends React.Component {
  containerRef = React.createRef()
  hasRoot = false

  componentDidMount() { this.updateApi(true) }
  componentDidUpdate(prevProps) {
    if (this.props.match.params.apiId !== prevProps.match.params.apiId ||
      this.props.match.params.stage !== prevProps.match.params.stage) {
      this.updateApi(false)
    }
  }

  componentWillUnmount() { this.containerRef = null }

  updateApi(isInitial) {
    if (isAuthenticated()) {
      return getApi(this.props.match.params.apiId || 'ANY', true, this.props.match.params.stage, isInitial)
        .then(api => {
          if (this.containerRef == null) return
          const elem = this.containerRef.current
          const isFirstLoad = !this.hasRoot

          this.hasRoot = elem != null
          if (api && elem != null) {
            const cell = {
              shouldPreauthorizeApiKey: false,
              preauthorizeApiKey: () => {
                cell.shouldPreauthorizeApiKey = true
              }
            }
            const swaggerUiConfig = {
              // domNode: this.containerRef.current,activi
              plugins: [SwaggerLayoutPlugin()],
              supportedSubmitMethods: [],
              spec: api.swagger,
              tryItOutEnabled: true,
              onComplete: (uiHandler) => {
                cell.uiHandler = uiHandler
                cell.preauthorizeApiKey()
              }
            }
            if (isRegistered()) {
              delete swaggerUiConfig.supportedSubmitMethods
            }

            this.setState({
              swaggerUiConfig
            })
            cell.preauthorizeApiKey = () => {
              if (store.apiKey) {
                cell.uiHandler.preauthorizeApiKey('api_key', store.apiKey)
              }
            }
            if (cell.shouldPreauthorizeApiKey) {
              cell.preauthorizeApiKey()
            }

            if (isFirstLoad) return updateUsagePlansAndApisList(true)
          }
        })
    } else {
      if (isInitial) {
        this.handleClick('v2')
      }
    }
  }

  state = {
    active: undefined,
    swaggerUiConfig: {
      // domNode: this.containerRef.current,
      supportedSubmitMethods: [],
      url: '/custom-content/api_v2.yaml',
      tryItOutEnabled: true
    }
  }

  handleClick = (e) => {
    if (e === 'v1' && this.state.active !== 'v1') {
      this.setState({
        active: e,
        swaggerUiConfig: {
          // domNode: this.containerRef.current,
          supportedSubmitMethods: [],
          url: '/custom-content/api_v1.yaml',
          tryItOutEnabled: true
        }
      })
    } else if (e === 'v2' && this.state.active !== 'v2') {
      this.setState({
        active: e,
        swaggerUiConfig: {
          // domNode: this.containerRef.current,
          supportedSubmitMethods: [],
          url: '/custom-content/api_v2.yaml',
          tryItOutEnabled: true
        }
      })
    }
  }

  render() {
    let errorHeader
    let errorBody

    if (store.apiList.loaded) {
      if (!store.apiList.apiGateway.length && !store.apiList.generic.length) {
        errorHeader = 'No APIs Published'
        errorBody = 'Your administrator hasn\'t added any APIs to your account. Please contact them to publish an API.'
      } else if (!store.api) {
        errorHeader = 'No Such API'
        errorBody = 'The selected API doesn\'t exist.'
      }
    }

    return (
      <>{
        isAuthenticated() ? (
          <PageWithSidebar
            sidebarContent={ <ApisMenu path={this.props.match} activateFirst={true} />}
            SidebarPusherProps={{ className: 'swagger-section' }}
          >
            <div className='swagger-ui-wrap' ref={this.containerRef} style={{ padding: '0 20px' }}>
              {errorHeader && errorBody && (
                <>
                  <Header as='h2' icon textAlign='center' style={{ padding: '40px 0px' }}>
                    <Icon name='warning sign' circular />
                    <Header.Content>{errorHeader}</Header.Content>
                  </Header>
                  <Container text textAlign='justified'>
                    <p>{errorBody}</p>
                  </Container>
                </>
              )}
              <SwaggerUI {...this.state.swaggerUiConfig} plugins={[SwaggerLayoutPlugin()]} />

            </div>
          </PageWithSidebar>
        ) : (
          <PageWithSidebar
            sidebarContent={
              <Menu vertical inverted size="large">
                <Menu.Item
                  name="Rental AVM V2"
                  active={this.state.active === 'v2'}
                  onClick={() => this.handleClick('v2')}
                />
                {/* <Menu.Item
                  name="Rental AVM V1"
                  active={this.state.active === 'v1'}
                  onClick={() => this.handleClick('v1')}
                /> */}
              </Menu>
            }
            SidebarPusherProps={{ className: 'swagger-section' }}>
            <div
              className="swagger-ui-wrap"
              ref={this.containerRef}
              style={{ padding: '0 20px' }}>
              <Segment
                vertical
                style={{ padding: '40px 0px', margin: '0 !important' }}>
                <Container fluid text textAlign="justified">
                  <SwaggerUI {...this.state.swaggerUiConfig}/>
                </Container>
              </Segment>
            </div>
          </PageWithSidebar>
        )}
      </>
    )
  }
})
