/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import { Button, ButtonGroup, Container, Dimmer, Header, Loader, Message, Modal, Table } from 'semantic-ui-react'

import * as MessageList from 'components/MessageList'
import * as AccountService from 'services/accounts'
import * as AccountsTable from 'components/Admin/Accounts/AccountsTable'
import * as AccountsTableColumns from 'components/Admin/Accounts/AccountsTableColumns'
import { store } from 'services/state'
import _ from 'lodash'
import { fetchUsageByAPIKey, mapUsageByDate } from 'services/api-catalog'
import { eachWeekOfInterval, sub, add, format, isSameWeek, startOfMonth, endOfMonth } from 'date-fns'

const RegisteredAccounts = () => {
  const [accounts, setAccounts] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedAccount, setSelectedAccount] = useState(undefined)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [promoteModalOpen, setPromoteModalOpen] = useState(false)
  const [viewUsageModalOpen, setViewUsageModalOpen] = useState(false)
  const [messages, sendMessage] = MessageList.useMessages()

  const refreshAccounts = async () => {
    const [all, admins] = await Promise.all([
      AccountService.fetchRegisteredAccounts(),
      AccountService.fetchAdminAccounts()
    ])

    const allMap = {}

    all.forEach(user => { allMap[user.UserId] = user })
    admins.forEach(admin => {
      admin.IsAdmin = true
      if (admin.EmailAddress === store.user.email) {
        admin.EmailAddress += ' (you)'
      }
      allMap[admin.UserId] = admin
    })

    return (setAccounts(Object.values(allMap)))
  }

  const isYou = (user) => {
    return (_.get(store, 'user.email') + ' (you)') === user.EmailAddress
  }

  // Initial load
  useEffect(() => {
    refreshAccounts().finally(() => setLoading(false))
  }, [])

  const onSelectAccount = useCallback(account => setSelectedAccount(account), [
    setSelectedAccount
  ])

  const onConfirmDelete = useCallback(async () => {
    setLoading(true)
    setDeleteModalOpen(false)
    try {
      await AccountService.deleteAccountByUserId(selectedAccount.UserId)
      sendMessage(dismiss => (
        <DeleteSuccessMessage account={selectedAccount} dismiss={dismiss} />
      ))
      await refreshAccounts()
    } catch (error) {
      sendMessage(dismiss => (
        <DeleteFailureMessage
          account={selectedAccount}
          dismiss={dismiss}
          errorMessage={error.message}
        />
      ))
    } finally {
      setLoading(false)
    }
  }, [sendMessage, selectedAccount])

  const onConfirmPromote = useCallback(async () => {
    setLoading(true)
    setPromoteModalOpen(false)
    try {
      await AccountService.promoteAccountByUserId(selectedAccount.UserId)
      sendMessage(dismiss => (
        <PromoteSuccessMessage account={selectedAccount} dismiss={dismiss} />
      ))
      await refreshAccounts()
    } catch (error) {
      sendMessage(dismiss => (
        <PromoteFailureMessage
          account={selectedAccount}
          dismiss={dismiss}
          errorMessage={error.message}
        />
      ))
    } finally {
      setLoading(false)
    }
  }, [sendMessage, selectedAccount])

  return (
    <Container fluid style={{ padding: '2em' }}>
      <Header as='h1'>Registered accounts</Header>
      <MessageList.MessageList messages={messages} />
      <AccountsTable.AccountsTable
        accounts={accounts}
        columns={[
          AccountsTableColumns.EmailAddress,
          AccountsTableColumns.IsAdmin,
          AccountsTableColumns.DateRegistered,
          AccountsTableColumns.RegistrationMethod,
          AccountsTableColumns.ApiKeyId
        ]}
        loading={loading}
        selectedAccount={selectedAccount}
        onSelectAccount={onSelectAccount}
      >
        <TableActions
          canDelete={!loading && selectedAccount && !isYou(selectedAccount)}
          onClickDelete={() => setDeleteModalOpen(true)}
          canPromote={!loading && selectedAccount}
          isAdmin={selectedAccount && selectedAccount.IsAdmin}
          onClickPromote={() => setPromoteModalOpen(true)}
          onViewUsageClick={() => setViewUsageModalOpen(true)}
        />
      </AccountsTable.AccountsTable>
      <DeleteAccountModal
        account={selectedAccount}
        onConfirm={onConfirmDelete}
        open={deleteModalOpen}
        isAdmin={selectedAccount && selectedAccount.IsAdmin}
        onClose={() => setDeleteModalOpen(false)}
      />
      <PromoteAccountModal
        account={selectedAccount}
        onConfirm={onConfirmPromote}
        open={promoteModalOpen}
        onClose={() => setPromoteModalOpen(false)}
      />
      {
        selectedAccount && viewUsageModalOpen ? (
          <ViewUsageModal
            account={selectedAccount}
            open={viewUsageModalOpen}
            onClose={() => setViewUsageModalOpen(false)}
          />
        ) : null
      }
    </Container>
  )
}
export default RegisteredAccounts

const TableActions = React.memo(
  ({ canDelete, onClickDelete, canPromote, onClickPromote, isAdmin, onViewUsageClick }) => (
    <ButtonGroup>
      <Button content='Delete' disabled={!canDelete} onClick={onClickDelete} />
      <Button
        content='Promote to Admin'
        disabled={!canPromote || isAdmin}
        onClick={onClickPromote}
      />
      <Button
        content='View Usage'
        onClick={onViewUsageClick}
      />
    </ButtonGroup>
  )
)

const DeleteAccountModal = React.memo(
  ({ account, onConfirm, open, onClose, isAdmin }) =>
    (account ? (
      <Modal size='small' open={open} onClose={onClose}>
        <Modal.Header>Delete account</Modal.Header>
        <Modal.Content>
          {isAdmin && (
            <Message negative>
              <Message.Header>Danger! This is an admin account.</Message.Header>
              <p><strong>Deleting an admin account could cause temporary loss of access and temporary inability to configure the developer portal.</strong></p>
            </Message>
          )}
          <p>
            Are you sure you want to delete the account <strong>{account.EmailAddress}</strong>, and de-activate the             associated API key? This action is irreversible.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button negative onClick={onConfirm}>
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
    ) : null)
)

const PromoteAccountModal = React.memo(
  ({ account, onConfirm, open, onClose }) =>
    account ? (
      <Modal size='small' open={open} onClose={onClose}>
        <Modal.Header>Confirm promotion</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to promote the account{' '}
            <strong>{account.EmailAddress}</strong> to Admin? This will allow
            the account to perform any Admin actions, including deleting and
            promoting other accounts.
          </p>
          <p>
            Only the owner of the Developer Portal can demote the account,
            through the Cognito console.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onConfirm} negative>Promote</Button>
        </Modal.Actions>
      </Modal>
    ) : null
)

const DeleteSuccessMessage = React.memo(({ account, dismiss }) => (
  <Message onDismiss={dismiss} positive>
    <Message.Content>
      Deleted account <strong>{account.EmailAddress}</strong>.
    </Message.Content>
  </Message>
))

const DeleteFailureMessage = React.memo(
  ({ account, errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          Failed to delete account <strong>{account.EmailAddress}</strong>.
        </p>
        {errorMessage && <p>Error message: {errorMessage}</p>}
      </Message.Content>
    </Message>
  )
)

const PromoteSuccessMessage = React.memo(({ account, dismiss }) => (
  <Message onDismiss={dismiss} positive>
    <Message.Content>
      Promoted account <strong>{account.EmailAddress}</strong>.
    </Message.Content>
  </Message>
))

const PromoteFailureMessage = React.memo(
  ({ account, errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          Failed to promote account <strong>{account.EmailAddress}</strong>.
        </p>
        {errorMessage && <p>Error message: {errorMessage}</p>}
      </Message.Content>
    </Message>
  )
)

const ViewUsageModal = React.memo(
  ({ account, open, onClose }) => {
    const [frequency, setFrequency] = useState('daily')
    const [loading, setLoading] = useState(false)
    const [usageData, setUsageData] = useState({})
    const [error, setError] = useState(undefined)
    const [retry, setRetry] = useState(0)
    const [title, setTitle] = useState('Last 90 days')
    const usagePlans = _.get(store, 'usagePlans')
    const usageID = usagePlans[0].id
    useEffect(() => {
      const endDate = new Date()
      const startDate = sub(new Date(), { days: 90 })
      setLoading(true)
      fetchUsageByAPIKey(usageID, account.ApiKeyId,
        startDate.toJSON().split('T')[0],
        endDate.toJSON().split('T')[0]).then((result) => {
        const data = mapUsageByDate(result.data, 'used')
        const resultUsageData = { daily: {} }
        data.forEach(elem => {
          resultUsageData.daily[format(new Date(elem[0]), 'yyyy-MM-dd')] = [elem[1], elem[2]]
        })
        setUsageData(resultUsageData)
      }).catch((err) => setError(err.message)).finally(() => setLoading(false))
    }, [])

    useEffect(() => {
      if (!usageData[frequency]) {
        if (error)setError(null)
        if (frequency === 'weekly') {
          setTitle('Last 10 Weeks')
          const newData = {}
          const weekIntervals = eachWeekOfInterval({ end: new Date(), start: sub(new Date(), { weeks: 10 }) })
          const dailyKeys = Object.keys(usageData.daily)
          for (let weekIndex = 0; weekIndex < weekIntervals.length; weekIndex++) {
            const week = weekIntervals[weekIndex]
            const formattedWeek = format(week, 'yyyy-MM-dd')
            newData[formattedWeek] = [0, 0]
            for (let index = 0; index < dailyKeys.length; index++) {
              const data = usageData.daily[dailyKeys[index]]
              if (isSameWeek(week, new Date(dailyKeys[index]))) {
                newData[formattedWeek][0] += data[0]
                newData[formattedWeek][1] = data[1]
              } else {
                continue
              }
            }
          }
          setUsageData({ ...usageData, [frequency]: newData })
        }
        if (frequency === 'monthly') {
          setTitle('Last 3 Months')
          const dates = [0, 1, 2].map(elem => {
            return {
              start: format(startOfMonth(sub(new Date(), { months: elem })), 'yyyy-MM-dd'),
              end: format(endOfMonth(sub(new Date(), { months: elem })), 'yyyy-MM-dd')
            }
          })
          setLoading(true)
          const promiseMap = dates.map(date => {
            return fetchUsageByAPIKey(usageID, account.ApiKeyId, date.start, date.end)
          })

          Promise.all(promiseMap).then(res => {
            const result = {}
            res.forEach(monthRes => {
              const monthKey = format(new Date(monthRes.data.startDate), 'MMM-yy')
              result[monthKey] = [0, 0]
              const data = mapUsageByDate(monthRes.data, 'used')
              data.forEach(row => {
                result[monthKey][0] += row[1]
                result[monthKey][1] = row[2]
              })
            })
            setUsageData({ ...usageData, [frequency]: result })
          }).catch((err) => setError(err.message)).finally(() => setLoading(false))
        }
      }
    }, [frequency, usageData, account, usageID, retry, error])

    return (
      <Modal size='small' open={open} onClose={onClose}>
        <Modal.Header>API Usage {title}</Modal.Header>
        <Modal.Content >
          <ButtonGroup>
            <Button
              toggle
              active={frequency === 'daily'}
              onClick={() => setFrequency('daily')}>
                Daily
            </Button>
            <Button
              toggle
              active={frequency === 'weekly'}
              onClick={() => setFrequency('weekly')}>
                Weekly
            </Button>
            <Button
              toggle
              active={frequency === 'monthly'}
              onClick={() => setFrequency('monthly')}>
                Monthly
            </Button>
          </ButtonGroup>
          {
            loading || !usageData[frequency] ? <Dimmer active><Loader/></Dimmer> : (
              error ? <Message>
                <Message.Content>
                  <p>{error}</p>
                  <Button size='small' onClick={() => setRetry(r => ++r)}>Retry</Button>
                </Message.Content>
              </Message> : (

                Object.keys(usageData[frequency]).length === 0 ? (
                  <Message>
                    <Message.Content>No data available for this User</Message.Content>
                  </Message>
                ) : (

                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Used</Table.HeaderCell>
                        <Table.HeaderCell>Remaining</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body style={{ maxHeight: 600, overflow: 'auto' }}>
                      {Object.keys(usageData[frequency]).map((key) => {
                        return (
                          <Table.Row key={key}>
                            <Table.Cell>{key}</Table.Cell>
                            <Table.Cell>{usageData[frequency][key][0]}</Table.Cell>
                            <Table.Cell>{usageData[frequency][key][1]}</Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                  </Table>
                )
              )
            )
          }
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    )
  }
)
